<template>
  <span>
    <h5>{{ field.label }} von/bis</h5>
    <basic-datepicker
      v-model="from"
      :columns="{input:['col-12']}"
      :horizontal="true"
      format="DD.MM.YYYY"
      sm
      placeholder="von"
    />
    <basic-datepicker
      v-model="to"
      :columns="{input:['col-12']}"
      :horizontal="true"
      format="DD.MM.YYYY"
      sm
      placeholder="bis"
    />
  </span>
</template>

<script>
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import moment from 'moment'
import mergeQueries from './mergeQueries'
export default {
  name: 'FilterDaterange',
  components: {
    BasicDatepicker

  },
  props: {
    field: {
      type: Object,
      required: true
    },
    opened: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      from: undefined,
      to: undefined
    }
  },
  computed: {
    query () {
      let query = { $and: [] }
      if (this.from) {
        const fromQ = { }
        fromQ[this.field.key] = { $gte: moment(this.from).startOf('day') }
        query = mergeQueries(query, { $and: [fromQ] })
      }
      if (this.to) {
        const toQ = { }
        toQ[this.field.key] = { $lte: moment(this.to).endOf('day') }
        query = mergeQueries(query, { $and: [toQ] })
      }
      if (query.$and.length === 0) delete query.$and
      return query
    }
  },
  watch: {
    from: {
      handler: 'updateFilterStatus'
    },
    to: {
      handler: 'updateFilterStatus'
    }
  },
  methods: {
    updateFilterStatus () {
      this.$emit('change', {
        isApplied: (this.from || this.to),
        query: this.query
      })
    },
    deleteFilter () {
      this.to = undefined
      this.from = undefined
    }
  }
}
</script>
